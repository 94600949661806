import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Header from '../Header';
import Typography from '../Typography';

const useStyles = makeStyles({
  header: {
    marginTop: '70px',
  },
  header2: {
    marginTop: '10px',
  },
  info: {
    padding: '50px',
  },
  content: {
    margin: '25px',
  },
  about: {
    paddingRight: '30px',
  },
  value: {
    padding: '20px',
  },
});

const data = [
  {
    key: 'Integrity,Honesty & Transparency',
    data:
      'At B&K, we uphold the basic values of integrity, honesty and transparency in the assignments we undertake. Hence, it is pertinent enough that as a team, we ensure that a high level of integrity is maintained within the team and with clients as well.',
  },
  {
    key: 'Client’s satisfaction',
    data:
      'At B&K, we prioritise client’s requirements first, instead of our own individual needs. We ensure, that our clients channelize their energy on expanding and carrying out their business activities, and we as a team, support them through our specialised consultancy services. We continue to remain independent and provide our opinion, in an unbiased manner. No doubt, we believe in a long lasting and trusted relationship, rather that accomplishment of short term individual goals.',
  },
  {
    key: 'Confidentiality',
    data: `Our firm, strongly adheres to the principles of maintaining confidentiality of data and information of our clients. We as a team, ensure that all data, which we acquired from the clients, in order to render our services, are highly protected, with limited access by our members as well. Our impetus, therefore, is to ensure that, the trust of our client on us is retained and our credibility is maintained.
      `,
  },
  {
    key: 'Professionalism',
    data:
      'We highly value and appreciate the core values of being punctual, sincere and carry with us the highest standard of ethical values, while dealing with people of all walks of life, whether, it be clients or our fellow colleagues.',
  },
  {
    key: 'Efficient services',
    data:
      'We are committed to provide our refined, professional and expertise services, which not only directly caters to individual client requirements, but also ensures transparency in process and procedures laid out by us, by adhering to highest ethical standards. We believe in timely, accurate and quality services, through our robust and efficient procedures and infrastructure in place. ',
  },
  {
    key: 'People',
    data:
      ' We take pride in nurturing young and raw talent, thereby tapping the core efficient areas of our members. As a team, we acknowledge the fact, that we are bound to help our team progress individually as well as professionally. Hence, at B&K, we have a pool of talented and dedicated employees as well, who are pro-active in undertaking responsibilities and provides the best of their services. In order to keep the young and agile talents motivated, we follow a rigorous approach of daily learning and knowledge sharing, along with performance linked incentive structure.  We treasure our people as we cherish our valued clients.',
  },
  {
    key: 'Social Responsibility',
    data:
      'As a team of young and enthusiastic people, we wish to contribute towards the society and also towards nation building. Hence, B&K, through its initiate- BHAGAT & KUMAR FOUNDATION, strives to eradicate poverty, providing education to poor and protecting environment, by going green.',
  },
];
export default function Values() {
  const classes = useStyles();

  return (
    <>
      <Header />
      <Paper elevation={0}>
        <section>
          <Grid container className={classes.header} spacing={2}>
            <Grid key={1} item xs={12} md={12}>
              <Typography
                variant="h3"
                align="center"
                marked="center"
                style={{ paddingTop: '20px' }}
              >
                Our Vision
              </Typography>
            </Grid>
          </Grid>
        </section>
        <Typography
          gutterBottom
          variant="subtitle1"
          className={classes.info}
          style={{ textAlign: 'justify' }}
        >
          In this contemporary world, our profession is profoundly different and
          versatile. Same is basically attributed to changes driven due to
          dynamics of globalisation and technology. Increased globalisation,
          triggers new markets for goods and services, thereby creating
          simultaneous pressures on emerging companies and individuals, to
          compete on innovation and well as price, through a chain of value
          addition. It also means major shifts in consumer markets, tax policy
          and workforce diversity. Technology is connecting people, spreading
          knowledge, saving time and freeing up resources.
          <br />
          <br />
          As we created, Vision 2025, it was quite apparent, that a ‘sense of
          purpose’ runs strongly though our organisation. As an organisation, we
          do have an understanding of our “The Motivating Factor”, each and
          every day, we interact and service our clients. The same is inherent,
          in the services we do, leading to creation of a better work and
          compliance culture. Each audit assignment, each tax return filed, each
          valuation done and all the interactions, fosters the clients to fulfil
          their purpose and contribute towards building a better economy,
          workplace and compliant world.
          <br />
          <b>
            &quot;We aspire to be the Standard of Excellence & Ethics, the first
            choice of the most sought-after clients and talent.&quot;
          </b>
          <br />
          <br />
        </Typography>
        <section>
          <Grid container spacing={2}>
            <Grid key={1} item xs={12} md={12}>
              <Typography
                variant="h3"
                align="center"
                marked="center"
                style={{ paddingTop: '20px' }}
              >
                Our Values
              </Typography>
            </Grid>
          </Grid>
        </section>
        <CardContent className={classes.content}>
          {data.map((value) => (
            <Paper key={value.key} className={classes.value}>
              <Typography gutterBottom variant="h5" component="h2">
                {value.key}
              </Typography>
              <Typography
                variant="body2"
                // color="textSecondary"
                component="p"
                className={classes.about}
                style={{ textAlign: 'justify' }}
              >
                {value.data}
              </Typography>
            </Paper>
          ))}
        </CardContent>
      </Paper>
    </>
  );
}
