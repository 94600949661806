import React from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline/CssBaseline';
import { Helmet } from 'react-helmet';
import theme from '../styles/theme';
import Values from '../components/Values';
import Footer from '../components/Footer';
import Logo from '../images/logo.png';

const FoundationPage = () => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Bhagat & Kumar - Chartered Accountants | Values</title>
      <meta name="title" content="Bhagat and Kumar - Chartered Accountants | Values" />
      <meta
        name="description"
        content="Bhagat & Kumar Chartered Accountants."
      />

      <meta property="og:title" content="Bhagat & Kumar Values" />
      <meta property="og:url" content={`${process.env.SITE_URL}/values`} />
      <meta
        property="og:description"
        content="At B&K, we uphold the basic values of integrity, honesty and transparency in the assignments we undertake. Hence, it is pertinent enough that as a team, we ensure that a high level of integrity is maintained within the team and with clients as well."
      />
      <meta property="og:image" content={Logo} />

      <meta property="twitter:card" content="values" />
      <meta property="twitter:image" content={Logo} />
      <meta property="twitter:site" content="@bhagatandkumar" />
      <meta property="twitter:title" content="Bhagat & Kumar Values" />
      <meta
        property="twitter:description"
        content="At B&K, we uphold the basic values of integrity, honesty and transparency in the assignments we undertake. Hence, it is pertinent enough that as a team, we ensure that a high level of integrity is maintained within the team and with clients as well."
      />
    </Helmet>
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Values />
      <Footer />
    </MuiThemeProvider>
  </>
);
export default FoundationPage;
